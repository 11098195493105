<template>
    <div class="footer-web">Ⓒ 2023 - Phát triển bởi 3Dart</div>
</template>

<script>
export default {

}
</script>

<style lang="css">
.footer-web {
    background: #e9b875;
    color: #fff;
    margin-top: 16px;
    padding: 16px 0;
    text-transform: uppercase;
}
</style>
